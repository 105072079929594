@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.banner {
    display: inline-flex;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000000;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;

    background: #F5F5F5;
    color: black;
    border-bottom: 1px solid black;

    font-family: 'Inter', sans-serif;
    font-size: 16px;
}

.banner--hidden {
    transform: translateY(-100%);
}

.banner__open {
    display: none;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 0;
    right: 10vw;
    width: 5vw;
    min-width: 60px;
    padding: 1vw 1vw 0;
    transform: translateY(100%);
    background: #F5F5F5;
    border: none;
    border-bottom: 1px solid black;
}

.banner--hidden .banner__open {
    display: flex;
}

.banner__open img {
    width: 80%;
    max-width: 100%;
}

.banner__rsaquo {
    transform: rotate(-90deg) translateY(-0.2vw);
    font-size: clamp(2rem, 2.5vw, 4rem);
}

.banner__left {
    display: flex;
    align-items: center;
}

.banner__left a {
    color: #ff3a1b;
    text-decoration: underline;
}

.banner__logo {
    display: none;
    margin-right: 36px;
}

.banner__copy {
    margin: 0;
    padding: 0;
}

@media screen and (min-width: 640px) {
    .banner__logo {
        display: inline;
    }
}

.banner__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1440px;
}

@media screen and (min-width: 640px) {
    .banner__container {
        flex-direction: row;
    }
}

.banner__close {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    padding: 0;
    margin-top: 8px;

    border: none;
    background: none;

    font-size: 24px;
}

.banner__close:hover {
    transform: scale(1.3);
    transition: all .4s ease;
    cursor: pointer;
}

@media screen and (min-width: 640px) {
    .banner__close {
        margin-top: 0;
        margin-left: 32px;
    }
}

.banner__right {
    display: flex;
    align-items: center;
    flex-direction: column;
}

@media screen and (min-width: 640px) {
    .banner__right {
        flex-direction: row;
    }
}

.banner__dropdown {
    display: flex;
    align-items: center;
    position: relative;
    padding: 10px 16px;
    margin-top: 8px;

    white-space: nowrap;
    background: rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    cursor: pointer;
}

@media screen and (min-width: 640px) {
    .banner__dropdown {
        margin-top: 0;
    }
}

.banner__dropdown::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    margin-left: 20px;

    border-top: 2px solid #000000;
    border-right: 2px solid #000000;
    transform: rotate(135deg);
}

.banner__dropdown select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    opacity: 0;
    cursor: pointer;
}

